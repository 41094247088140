<template>
  <div>
    <div class="d-flex align-items-center">
      <h1 class="h3">
        {{ $t('withdrawal.history_wallet') }}
      </h1>
      <div class="ml-auto" />
    </div>
    <a-row
      :gutter="24"
      class="mt-5"
      type="flex"
      align="bottom"
    >
      <a-col :span="6">
        <label class="mb-1">Search</label>
        <a-input-search
          v-model="params.search"
          size="large"
          :placeholder="$t('utils.search')"
          :loading="isLoading"
          :disabled="isLoading"
          allow-clear
          @search="onChangeTab"
        >
          <a-button slot="enterButton" class="bg-primary-blue text-white">
            <a-icon type="search" />
          </a-button>
        </a-input-search>
      </a-col>
      <a-col :span="6">
        <label class="mb-1 mr-2">{{ $t('discount.period') }}</label>
        <a-range-picker v-model="dates" format="DD MMM YYYY" size="large" @change="onChangeDate" />
      </a-col>
    </a-row>
    <div class="mt-4">
      <a-table
        class="discount-list"
        :data-source="historyWalletList"
        :row-key="record => record.id"
        :columns="columns"
        :loading="isLoading"
        :pagination="false"
        :scroll="scroll"
      >
        <template slot="created_at" slot-scope="text">
          {{ $moment(text).format(' HH:MM, DD-MM-YYYY') }}
        </template>
        <template slot="amount" slot-scope="text">
          {{ text | currency }}
        </template>
        <template slot="start_date" slot-scope="text, record">
          {{ $moment(record.start_date.replace(' ','')).format('DD MMM YYYY HH:mm') + ' - ' + $moment(record.end_date.replace(' ','')).format('DD MMM YYYY HH:mm') }}
        </template>
        <template slot="note" slot-scope="text, record">
          {{ record.status === 'DRAFT' ? `Booking ${text}` : text }}
        </template>
      </a-table>
    </div>
    <div class="mt-4 text-right">
      <a-pagination
        v-model="params.page"
        :page-size="params.limit"
        :page-size-options="sizeOptions"
        :total="totalHistory"
        :show-total="(total, range) => $t('utils.pagination_show_total', { perpage: `${range[0]} - ${range[1]}`, total })"
        :disabled="isLoading"
        show-size-changer
        @change="onPageChange"
        @showSizeChange="onShowSizeChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListProductDiscount',
}
</script>
<script setup>
import { getWalletHistory } from '@/api/channels/distributor'
import debounce from 'lodash/debounce'
import { computed, getCurrentInstance, onMounted, reactive, ref, toRefs, watch } from 'vue'
import { columnsHistoryWallet } from '@/data/columns'

const { $i18n, $route, $router, $store, $moment } = getCurrentInstance().proxy.$root

const INITIAL_SORT = {
  sort: ['createdAt'],
  order: ['DESC'],
}

const INITIAL_PARAMS = {
  search: '',
  start_date: '',
  end_date: '',
  status: 'ALL',
  sort: 'createdAt',
  order: 'DESC',
  page: 1,
  limit: 10,
}

// Data
const isLoading = ref(false)
const totalHistory = ref(0)
const historyWalletList = ref([])
const dates = ref([])
const businessId = ref(Object.keys($store.getters['user/businessListObjectByKey'])[0])
const screenHeight = ref(((screen.height - 900) * 0.4) + (screen.height * 0.4))
const visible = ref(false)
const columns = ref(columnsHistoryWallet)
const selectedList = ref([])
const tabs = ref(['ALL', 'WAITING_APPROVAL', 'ACTIVE', 'INACTIVE'])
const sizeOptions = ref(['10', '20', '30', '40', '50'])
const sortOptions = ref([
  { label: $i18n.t('discount.createdAt'), value: 'createdAt' },
])
const orderOptions = ref([
  { label: $i18n.t('discount.newest'), value: 'DESC' },
  { label: $i18n.t('discount.oldest'), value: 'ASC' },
])
const sorting = reactive(INITIAL_SORT)
const params = reactive(INITIAL_PARAMS)
const overlayStyle = reactive({ width: '150px' })
const colors = reactive({
  ACTIVE: 'blue',
  WAITING_APPROVAL: 'orange',
  NOT_ACTIVE: 'red',
  INACTIVE: 'red',
})

// Refs
const refSorting = toRefs(sorting)
const refParams = toRefs(params)

// Computed
const business = computed(() => Object.keys($store.getters['user/businessListObjectByKey'])[0])
const scroll = computed(() => ({ y: screenHeight.value > 360 ? screenHeight.value : 360 }))
const tHeight = computed(() => {
  return !historyWalletList.value.length ? '0px'
    : screenHeight.value > 360 ? `${screenHeight.value}px`
    : '360px'
})
const permission = computed(() => $store.getters['user/can']('discount', 'reverse'))

// Methods
const toAddDiscount = (id, type = '') => {
  const query = {
    ...$route.query,
    discount_id: id,
    is_detail: type == 'detail' ? true : undefined,
    is_duplicate: type == 'duplicate' ? true : undefined,
  }

  $router.push({
    path: `/promotion-channel/${$route.params.id}/discount/add`,
    query,
  })
}

const init = () => {
  selectedList.value = []
  refSorting.value = INITIAL_SORT
  refParams.value = INITIAL_PARAMS
  fetchHistoryWallet()
}

const fetchHistoryWallet = debounce(async () => {
  isLoading.value = true
  await getWalletHistory({
    business_id: businessId.value,
    seller_id: $route.query.seller_id,
    params,
  })
  .then(({ data: { data: response } }) => {
    historyWalletList.value = response?.contents || []
    totalHistory.value = response?.total_elements || 0
  })
  .catch(() => {
    historyWalletList.value = []
    totalHistory.value = 0
  })
  .finally(() => isLoading.value = false)
}, 500)

const onMenuClick = (e) => {
  if (e.key !== '2' && e.key !== '4') {
    visible.value = false
  }
}

const onSortChange = (value, type) => {
  const newValue = value.length ? value.filter(val => val !== params[type]) : [params[type]]
  sorting[type] = newValue
  params[type] = newValue.toString()
  fetchHistoryWallet()
}

const onSelectChange = (value) => {
  selectedList.value = value
}

const onChangeTab = () => {
  params.page = 1
  fetchHistoryWallet()
}

const onChangeStatus = (value) => {
  params.page = 1
  params.status = value
  fetchHistoryWallet()
}

const onChangeDate  = (date) => {
  params.page = 1
  params.start_date = date[0] ? $moment(date[0]).startOf('day').format() : ''
  params.end_date = date[1] ? $moment(date[1]).endOf('day').format() : ''
  fetchHistoryWallet()
}

const onPageChange = (pageNumber) => {
  selectedList.value = []
  params.page = pageNumber
  fetchHistoryWallet()
}

const onShowSizeChange = (current, pageSize) => {
  selectedList.value = []
  params.page = current
  params.limit = pageSize
  fetchHistoryWallet()
}

// Watch
watch(() => 
  business.value, (newValue) => {
    businessId.value = newValue
    init()
  })
watch(() => 
  $route, (newValue) => {
    if (newValue.name === $route.name) {
      // init()
    }
  })
watch(() => 
  permission.value, () => {
    // init()
  })

// Mounted
onMounted(init)
</script>

<style lang="scss">
.fs-14 {
  font-size: 14px !important;
}
.fw-bold {
  font-weight: bold;
}
.text-capitalize {
  text-transform: capitalize
}
.sorting-checkbox {
  .ant-checkbox-group-item {
    display: block !important;
    padding: 5px 0;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .ant-checkbox-inner {
    border-color: transparent !important;
  }
  .ant-checkbox-checked {
    &::after {
      border-color: transparent !important;
    }
    .ant-checkbox-inner {
      background-color: transparent !important;

      &::after {
        border-color: #ff0a54 !important;
      }
    }
  }
}
.dropdown-title {
  font-size: 12px;
  font-weight: bold;
  color: #6c757d;
}
.discount-list div.ant-table-body {
  min-height: v-bind(tHeight);
}
.ant-btn-info {
  color: #2196f3 !important;
  border-color: #2196f3 !important;
}
.ant-dropdown-menu-item {
  background: #fff !important;
}
.ant-table-row {
  background-color: #fff !important;

  &.ant-table-row-selected > td {
    background-color: #fff !important;
  }
}
.pca-tab-store {
  .ant-tabs-bar {
    background-color: #fff;
    margin-bottom: 0;
    border-bottom-color: transparent;
  }
  .ant-tabs-nav .ant-tabs-tab:hover,
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #ff0a54;
  }
  .ant-tabs-ink-bar {
    background-color: #ff0a54;
  }
}
.select-antd-default.ant-select-lg .ant-select-selection__rendered {
  line-height: 38px !important;
}
</style>